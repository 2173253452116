<template>
    <div class="post-controls">
        <v-btn
            color="blue darken-1"
            text
            @click="back"
        >
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t('posts.return') }}
        </v-btn>

        <v-btn
            v-if="urlNextPost"
            color="blue darken-1"
            text
            @click="clicNextPost"
        >
            {{ $t('posts.nextPost') }}
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'NextPostBtn',
    props: {
        urlNextPost: {
            type: String,
            default: ''
        }
    },
    methods: {
        clicNextPost() {
            this.$router.push(this.urlNextPost);
            this.$emit('changed:post');
        },
        back() {
            this.$router.go(-1);
            // this.$emit('changed:post');
        }
    }
};
</script>

<style lang="scss" scoped>
.post-controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
